import React from 'react';
import type { FontSizeValue } from '@/utils/convertFontSize';
import type { ContentfulComponentDefinition } from '../ContentfulComponentDefinition';
import type { TextCardDefinition } from './TextCardItem/TextCardItem';
import TextCardItem from './TextCardItem/TextCardItem';

export type TextCardContainerDefinition = ContentfulComponentDefinition & {
  cards: TextCardDefinition[],
  fontSize: FontSizeValue
  textColor?: {
    value: string;
  }
  hoverTextColor?: {
    value: string;
  }
  background?: {
    value: string;
  }
  hoverBackground?: {
    value: string;
  }
  cornerRounding?: 'None' | 'Rounded';
  hoverAnimation?: 'None' | 'Expand';
  id?: string,
  internal: {
    type: 'ContentfulComponentTextCardContainer'
  }
};

export type TextCardProps = {
  content: TextCardContainerDefinition
};

const TextCard = ({
  content,
}: TextCardProps) => {
  const {
    cards,
    ...rest
  } = content;

  return (
    <div className="flex flex-row flex-wrap gap-2">
      {
      cards.map((card) => (
        <TextCardItem key={card.id} {...rest} content={card} />
      ))
    }
    </div>
  );
};

export default TextCard;
