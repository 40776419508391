import React from 'react';

import Pdf from '#components/Pdf';
import Text from '#contentful/Text';
import Image from '#contentful/Image';
import Quote from '#contentful/Quote';
import Button from '#contentful/Button';
import Video from '#contentful/Video';
import Form from '#contentful/Form';
import StatCard from '#contentful/StatCard';
import TextButton from '#contentful/TextButton';
import Accordion from '#contentful/AccordionList';
import ImageCollage from '#contentful/ImageCollage';
import PersonWithQuote from '#contentful/PersonWithQuote';
import Person from '#contentful/Person';
import SuggestedContent from '#contentful/SuggestedContent';

import type { PdfDefinition } from '#components/Pdf';
import type { TextDefinition } from '#contentful/Text';
import type { ImageDefinition } from '#contentful/Image';
import type { QuoteDefinition } from '#contentful/Quote';
import type { ButtonDefinition } from '#contentful/Button';
import type { VideoDefinition } from '#contentful/Video';
import type { FormDefinition } from '#contentful/Form';
import type { StatCardDefinition } from '#contentful/StatCard';
import type { TextButtonDefinition } from '#contentful/TextButton';
import type { ImageCollageDefinition } from '#contentful/ImageCollage';
import type { AccordionListDefinition } from '#contentful/AccordionList';
import type { PersonWithQuoteDefinition } from '#contentful/PersonWithQuote';
import type { PersonComponentDefinition } from '#contentful/Person';
import type { SuggestedContentDefinition } from '#contentful/SuggestedContent';
import type { TextCardContainerDefinition } from '@/components/ContentfulComponent/TextCard/TextCard';
import TextCard from '@/components/ContentfulComponent/TextCard/TextCard';

export type CarouselComponentDefinition = (
  TextDefinition
  | ImageDefinition
  | QuoteDefinition
  | ButtonDefinition
  | TextButtonDefinition
  | VideoDefinition
  | FormDefinition
  | AccordionListDefinition
  | StatCardDefinition
  | ImageCollageDefinition
  | PersonWithQuoteDefinition
  | PdfDefinition
  | PersonComponentDefinition
  | SuggestedContentDefinition
  | TextCardContainerDefinition
);

export type CarouselComponentProps = {
  content: CarouselComponentDefinition,
};

const CarouselComponent = ({
  content,
}: CarouselComponentProps) => {
  if (!content.internal?.type) {
    return null;
  }

  switch (content.internal?.type) {
    case 'ContentfulComponentText':
      return (
        <Text
          key={content.id}
          content={content as TextDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    case 'ContentfulComponentImage':
      return <Image key={content.id} content={content as ImageDefinition} />;
    case 'ContentfulComponentQuote':
      return <Quote key={content.id} content={content as QuoteDefinition} />;
    case 'ContentfulComponentButton':
      return (
        <Button
          key={content.id}
          content={content as ButtonDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    case 'ContentfulComponentTextButton':
      return (
        <TextButton
          key={content.id}
          content={content as TextButtonDefinition}
          sx={{ px: { xs: 2, md: 0 } }}
        />
      );
    case 'ContentfulComponentVideo':
      return (
        <Video
          key={content.id}
          content={content as VideoDefinition}
        />
      );
    case 'ContentfulComponentForm':
      return (
        <Form
          key={content.id}
          content={content as FormDefinition}
        />
      );
    case 'ContentfulComponentAccordion':
      return (
        <Accordion
          key={content.id}
          content={content as AccordionListDefinition}
        />
      );
    case 'ContentfulComponentStatisticsCard':
      return (
        <StatCard
          key={content.id}
          content={content as StatCardDefinition}
        />
      );
    case 'ContentfulComponentImageCollage':
      return (
        <ImageCollage
          key={content.id}
          content={content as ImageCollageDefinition}
        />
      );
    case 'ContentfulComponentPersonWithQuote':
      return (
        <PersonWithQuote
          key={content.id}
          content={content as PersonWithQuoteDefinition}
        />
      );
    case 'ContentfulComponentPdf':
      return (
        <Pdf
          key={content.id}
          {...content as PdfDefinition}
        />
      );
    case 'ContentfulComponentPerson':
      return (
        <Person
          key={content.id}
          content={content as PersonComponentDefinition}
        />
      );
    case 'ContentfulComponentSuggestedContent':
      return (
        <SuggestedContent
          key={content.id}
          content={content as SuggestedContentDefinition}
        />
      );
    case 'ContentfulComponentTextCardContainer':
      return (
        <TextCard
          key={content.id}
          content={content as TextCardContainerDefinition}
        />
      );
    default:
      console.error(`Unexpected component type: ${JSON.stringify(content)}`);
      return null;
  }
};

export default CarouselComponent;
