import React from 'react';
import type { PropsWithChildren } from 'react';
import {
  CircularProgress, styled, Typography, Button as MuButton,
} from '@mui/material';
import { isString } from 'lodash/fp';

import SmartLink from '#components/SmartLink';

export type ButtonVariant = 'primary' | 'secondary' | 'bio' | 'white' | 'green' | 'Transparent (Green)';

export type ButtonProps = PropsWithChildren<{
  variant: ButtonVariant,
  type?: 'button' | 'submit' | 'reset' | undefined,
  colorOverride?: string,
  href?: string,
  slug?: string,
  anchorTag?: string,
  openInNewWindow?: boolean,
  isInTable?: boolean,
  className?: string,
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  isLoading?: boolean,
  fontColor?: string,
}>;

const Button = ({
  variant = 'primary',
  colorOverride,
  href,
  slug,
  anchorTag,
  openInNewWindow,
  type = 'button',
  children,
  isInTable,
  className,
  onClick,
  isLoading,
  fontColor,
}: ButtonProps) => {
  const styles = colorOverride
    ? { backgroundColor: colorOverride }
    : {};

  const variantStyle = variant === 'primary' || variant === 'white' || variant === 'green' || variant === 'Transparent (Green)'
    ? 'contained'
    : 'outlined';

  const px = variant === 'bio' || isInTable
    ? 3
    : 6.5;
  const py = variant === 'bio' || isInTable
    ? 1
    : 2;

  return (
    <div
      className={className}
    >
      <SmartLink
        href={href}
        slug={slug}
        anchorTag={anchorTag}
        sx={{ textDecoration: 'none' }}
        openInNewWindow={isString(href) || openInNewWindow}
      >
        <MuButton
          disabled={isLoading}
          onClick={(e) => (onClick ? onClick(e) : '')}
          sx={{
            borderRadius: 500,
            ...(variant === 'white' && {
              backgroundColor: '#fff',
              '&:hover': {
                backgroundColor: '#fff',
                opacity: 0.9,
              },
            }),
            ...(variant === 'green' && {
              backgroundColor: '#5bc2a7',
              '&:hover': {
                backgroundColor: '#5bc2a7',
                opacity: 0.9,
              },
            }),
            ...(variant === 'Transparent (Green)' && {
              backgroundColor: 'transparent',
              border: '1px solid #5bc2a7',
              '&:hover': {
                backgroundColor: '#5bc2a7',
                opacity: 0.9,
                color: 'white',
              },
            }),
            ...styles,
            px,
            py,
            position: 'relative',
            color: fontColor,
          }}
          variant={variantStyle}
          color="primary"
          type={type}
        >
          {isLoading && (
            <CircularProgress
              size={15}
              sx={{
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}

          <Typography
            variant="button1"
            sx={{ visibility: isLoading ? 'hidden' : 'unset' }}
          >
            {children}
          </Typography>
        </MuButton>
      </SmartLink>
    </div>
  );
};

export default styled(Button)({});
