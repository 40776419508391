import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'gatsby-plugin-react-i18next';
import { slashySlug } from '#components/SmartLink';
import { getFontSize, type FontSizeValue } from '@/utils/convertFontSize';
import clsx from 'clsx';
import type { ContentfulComponentDefinition } from '../../ContentfulComponentDefinition';

export type TextCardDefinition = ContentfulComponentDefinition & {
  title: string,
  link?: {
    slug: string
  },
  props: any,
  id?: string,
  internal: {
    type: 'ContentfulSubComponentTextCard'
  }
};

export type TextCardProps = {
  content: TextCardDefinition;
  background?: {
    value: string;
  }
  hoverBackground?: {
    value: string;
  }
  textColor?: {
    value: string;
  }
  hoverTextColor?: {
    value: string;
  }
  cornerRounding?: 'None' | 'Rounded';
  hoverAnimation?: 'None' | 'Expand';
  fontSize: FontSizeValue
};

const TextCardItem = ({
  content,
  background,
  hoverBackground,
  cornerRounding,
  hoverAnimation,
  textColor,
  hoverTextColor,
  fontSize,
}: TextCardProps) => {
  const {
    title,
    link,
    props,
  } = content;

  const fontSizeValue = getFontSize(fontSize ?? 'X-Small (0.75rem)');
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link
      className={clsx(
        'h-10 inline-flex items-center justify-center no-underline whitespace-nowrap shadow-current',
        cornerRounding === 'Rounded' && 'rounded',
        hoverAnimation === 'Expand' && 'transition-transform duration-500 transform hover:scale-105',
      )}
      to={slashySlug(link?.slug)}
      style={{
        lineHeight: 2,
        backgroundColor: isHovered ? hoverBackground?.value : background?.value,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...props}
    >
      <Typography
        variant="h4"
        className={`mb-0 pt-0 pb-0 px-3 py-2 text-center ${fontSizeValue} inline-flex uppercase`}
        color={isHovered ? hoverTextColor?.value : textColor?.value}
      >
        {title}
      </Typography>
    </Link>
  );
};

export default TextCardItem;
