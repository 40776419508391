import React from 'react';
import TypingEffect from 'react-typing-effect';

type AnimationWritingProps = {
  animationOptions: string,
};

export type JsonValue = {
  textBeforeHtmlTag: keyof JSX.IntrinsicElements;
  textBefore: string;
  textBeforeFontColor: string;
  textBeforeFontSize: string;
  textBeforeFontWeight: string;
  textAnimatedHtmlTag: keyof JSX.IntrinsicElements;
  textAnimated: string;
  textAnimatedFontColor: string;
  textAnimatedFontSize: string;
  textAnimatedFontWeight: string;
  animatedBorder: string;
  textAfterHtmlTag: keyof JSX.IntrinsicElements;
  textAfter: string;
  textAfterFontColor: string;
  textAfterFontSize: string;
  textAfterFontWeight: string;
};

const AnimationTyping = ({ animationOptions }: AnimationWritingProps) => {
  const animationOptionsObject = JSON.parse(animationOptions);

  const {
    textBeforeHtmlTag: Before = 'h1',
    textBefore,
    textBeforeFontSize,
    textBeforeFontColor,
    textBeforeFontWeight,
    textAnimatedHtmlTag: Animated = 'h1',
    textAnimated,
    textAnimatedFontSize,
    textAnimatedFontColor,
    textAnimatedFontWeight,
    animatedBorder,
    textAfterHtmlTag: After = 'h1',
    textAfter,
    textAfterFontSize,
    textAfterFontColor,
    textAfterFontWeight,
  } = animationOptionsObject || {};

  const createStyle = (
    fontSize: string,
    fontcolor: string,
    fontWeight: string,
    border?: boolean,
  ) => {
    const style = {
      fontFamily: 'Poppins',
      fontWeight,
      fontSize: fontSize ? `${fontSize}` : 'inherit',
      color: fontcolor || 'inherit',
      border: 'none',
      padding: '0px',
      borderRadius: '0px',
      boxShadow: 'none',
    };

    if (border) {
      style.border = '1px solid #ccc';
      style.padding = '10px';
      style.borderRadius = '10px';
      style.boxShadow = 'box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1)';
    }

    return style;
  };

  const textStyleBefore = createStyle(
    textBeforeFontSize,
    textBeforeFontColor,
    textBeforeFontWeight,
    false,
  );
  const textStyleAnimated = createStyle(
    textAnimatedFontSize,
    textAnimatedFontColor,
    textAnimatedFontWeight,
    animatedBorder === 'yes',
  );
  const textStyleAfter = createStyle(
    textAfterFontSize,
    textAfterFontColor,
    textAfterFontWeight,
    false,
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '0.2em',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Before style={textStyleBefore}>{textBefore}</Before>
      &nbsp;
      <Animated style={textStyleAnimated}>
        <TypingEffect
          text={
            textAnimated.includes(',') ? textAnimated.split(',') : textAnimated
          }
          speed={100}
          eraseSpeed={50}
          eraseDelay={1500}
          typingDelay={500}
        />
      </Animated>
      &nbsp;
      <After style={textStyleAfter}>{textAfter}</After>
    </div>
  );
};

export default AnimationTyping;
