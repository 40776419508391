import React from 'react';
import { Box } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import type { IGatsbyImageData } from 'gatsby-plugin-image';

import type { ContentfulComponentDefinition } from '#components/ContentfulComponent';
import type { LinkDefinition } from '#contentful/Link';
import SmartLink from '#components/SmartLink';
import Button from '#components/Button';
import download from '#utils/download';
import type { ContentfulBorderDefinition } from '#hooks/useBorder';
import useBorder from '#hooks/useBorder';

import type { ContentfulAlignment } from '#hooks/useContentfulAlignment';
import useContentfulAlignment from '#hooks/useContentfulAlignment';
import useResponsiveJustifyContent from '#hooks/useResponsiveJustifyContent';
import useBorderRoundingToPx from '#hooks/useBorderRoundingToPx';
import type { BorderRounding } from '#hooks/useBorderRoundingToPx/useBorderRoundingToPx';
import { CardBox } from './styles';

export type GatsbyImageProps = {
  gatsbyImageData: IGatsbyImageData,
  alt: string,
  url: string,
  id: string,
  file: {
    details: {
      image: {
        width: number,
        height: number,
      }
    }
  }
};

export type ImageDefinition = ContentfulComponentDefinition & {
  addAnimationComImg?: boolean,
  addCardStyling?: boolean,
  displayDownloadBtn?: boolean,
  downloadBtnLabel?: string,
  isIcon?: boolean,
  image: GatsbyImageProps,
  border?: ContentfulBorderDefinition,
  link?: LinkDefinition,
  horizontalAlignment?: ContentfulAlignment,
  caption?: string,
  imageWidth?: string,
  cornerRoundingSize?: BorderRounding,
  internal: {
    type: 'ContentfulComponentImage'
  }
};

export type ImageProps = {
  content: ImageDefinition,
};

const Image = ({
  content: {
    addAnimationComImg,
    image,
    link,
    isIcon,
    addCardStyling,
    displayDownloadBtn,
    downloadBtnLabel,
    horizontalAlignment,
    border,
    imageWidth,
    caption,
    cornerRoundingSize,
  },
}: ImageProps) => {
  const { gatsbyImageData, alt, url } = image ?? {};
  const { href, page, anchorTag } = link ?? {};

  const BoxWrapper = addCardStyling ? CardBox : Box;
  const classNames = addAnimationComImg ? 'reveal transition-ease-1' : '';
  const borderStyles = useBorder(border);

  const justifyContent = useContentfulAlignment(horizontalAlignment ?? 'center');
  const responsiveJustifyContent = useResponsiveJustifyContent({
    xs: justifyContent,
  });

  const downloadImage = () => {
    if (!image || !image.url) {
      return;
    }

    let fileName = image.url;
    const extension = image.url.split('.').pop();

    if (image.alt) {
      const name = image.alt
        .trim()
        .replaceAll(' ', '-')
        .replaceAll(/[^a-zA-Z0-9-]/g, '');
      fileName = `${name}.${extension}`;
    }
    download(fileName, image.url);
  };

  const imageWidthStyles = imageWidth ? { width: `${imageWidth}%` } : { width: '100%' };

  const borderRadious = useBorderRoundingToPx(cornerRoundingSize);

  const renderImage = () => {
    const isNotGatsbyImage = !/\.(png|jpg|jpeg|webp|avif)$/i.test(url ?? '');

    const imageElement = isNotGatsbyImage ? (
      <img
        width="100%"
        src={url}
        alt={alt}
        loading="lazy"
      />
    ) : (
      <GatsbyImage
        image={gatsbyImageData}
        loading="lazy"
        alt={alt}
      />
    );

    return (
      <figure style={{
        ...imageWidthStyles,
        ...(borderStyles && {
          ...borderStyles,
          border: `${borderStyles.border}px solid ${borderStyles.borderColor}`,
        }),
        margin: 0,
        borderRadius: borderRadious,
      }}
      >
        <div style={{
          overflow: 'hidden',
          borderRadius: borderRadious,
        }}
        >
          {imageElement}
        </div>
        {caption
        && (
          <div
            className="text-center mt-2 font-roboto-regular text-link italic"
          >
            {caption}
          </div>
        )}
      </figure>
    );
  };

  return (
    <>
      <SmartLink
        href={href}
        slug={page?.slug}
        anchorTag={anchorTag?.id}
        className="no-underline hover:underline hover:decoration-link"
      >
        <BoxWrapper
          className={classNames}
          display="flex"
          justifyContent={responsiveJustifyContent}
          maxWidth={{ xs: 'none', md: isIcon ? '50%' : 'none', lg: 'none' }}
          sx={{
            mx: addCardStyling
              ? { xs: 2, md: 0 }
              : { xs: 0, md: isIcon ? 'auto' : 0, lg: 0 },
            opacity: 1,
          }}
        >
          {renderImage()}
        </BoxWrapper>
      </SmartLink>

      {displayDownloadBtn && image && (
        <Box
          display="flex"
          justifyContent="center"
          mb={{ xs: 3, sm: 5, lg: 0 }}
          mt={{ xs: 2, sm: 3 }}
        >
          <Button
            onClick={downloadImage}
            variant="primary"
          >
            {downloadBtnLabel ?? 'Download'}
          </Button>
        </Box>
      )}
    </>
  );
};

export default Image;
