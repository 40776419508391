import React from 'react';
import { Typography, useTheme } from '@mui/material';

import type { LinkDefinition } from '#contentful/Link';
import useColor from '#hooks/useColor';
import SmartLink from '#components/SmartLink';
import type {
  ContentfulComponentDefinition,
} from '#contentful/ContentfulComponentDefinition';
import { getFontSize, type FontSizeValue } from '@/utils/convertFontSize';

export type TextVariantDefinition = TextVariantProps
& ContentfulComponentDefinition
& {
  internal: {
    type: 'ContentfulComponentTextVariant',
  },
};

export type TextVariantProps = {
  variant?: Parameters<(typeof Typography)>[0]['variant'] | '',
  color?: string,
  text?: {
    text: string,
  },
  link?: LinkDefinition,
  fontSize?: FontSizeValue
};

const TextVariant = ({
  variant,
  color,
  text,
  link,
  fontSize = 'X-Large (1.75rem)',
}: TextVariantProps) => {
  const { palette } = useTheme();
  const impColor = useColor(
    'color',
    color,
    palette.color?.impblue300,
  );

  const fontSizeValue = getFontSize(fontSize ?? 'X-Large (1.75rem)');

  return (
    <Typography
      variant={variant || 'h4'}
      px={2}
      m={0}
      className={fontSizeValue}
    >
      {link
        ? (
          <SmartLink
            href={link.href}
            slug={link.page?.slug}
            anchorTag={link.anchorTag?.id}
            sx={{
              color: impColor,
              lineHeight: '1.2',
            }}
          >
            {text?.text}
          </SmartLink>
        ) : (
          text?.text
        )}
    </Typography>
  );
};

export default TextVariant;
